<template>
  <el-card shadow="never" style="margin-bottom:20px">
    <template #header>
      <span class="SubHead">网站消耗</span>
    </template>
    <el-form ref="formRef" :inline="true" :model="form">
      <el-form-item label="消耗日期" style="margin-right: 20px;" prop="timeRange">
        <el-date-picker v-model="form.timeRange" type="daterange" size="small" start-placeholder="开始"
          end-placeholder="结束" value-format="X" :disabled-date="e => base.getDefaultDisabledDate(e, 1704038400)"
          range-separator="至" style="width: 230px;" />
      </el-form-item>
      <el-form-item label="广告账户" style="margin-right: 20px;" prop="account">
        <el-input v-model="form.account" placeholder="请输入广告账户ID或账户名称" size="small" style="width: 230px;" clearable />
      </el-form-item>
      <el-form-item label="网站" prop="site_name">
        <el-input v-model="form.site_name" placeholder="请输入网站" size="small" style="width: 230px;" clearable />
      </el-form-item>
      <el-form-item style="margin-left: 60px;">
        <el-button type="primary" @click="fetchData" size="small">查询</el-button>
        <el-button @click="resetFormBtn" size="small">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>

  <vxe-grid ref="xGridRef" v-bind="gridOptions">
    <template #toolbar_buttons>
      <span class="table-title">网站消耗详情</span>
    </template>
  </vxe-grid>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import base from '@/base/filters/base'
import api from '@/request/api/report'

const xGridRef = ref();
const formRef = ref();

const form = ref({
  timeRange: base.getDefaultDuration(),
  account: "",
  site_name: "",
});

const gridOptions = reactive({
  id: "vxe-grid",
  border: true,
  keepSource: true,
  showOverflow: true,
  columnConfig: {
    resizable: true,
  },
  treeConfig: { expandAll: true, reserve: true },
  rowConfig: { keyField: 'program' },
  columns: [
    { field: "program", title: "项目/网站/广告账户ID", minWidth: 300, treeNode: true, fixed: 'left' },
    { field: "account_name", title: "广告账户名称", minWidth: 300, fixed: 'left' },
    {
      field: "spent",
      title: "消耗",
      width: 200,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "clicks",
      title: "点击数",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "impressions",
      title: "展示数",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "reach",
      title: "转化数",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "add_to_cart",
      title: "加购数",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "checkout",
      title: "结账数",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "purchase",
      title: "购买数",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "purchase_value",
      title: "购买价值",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "CPM",
      title: "CPM",
      width: 140,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "CPC",
      title: "CPC",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "CTR",
      title: "CTR",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "CVR",
      title: "CVR",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "CPA",
      title: "CPA",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "ROAS",
      title: "ROAS",
      width: 100,
      headerAlign: "center",
      align: "right",
    }
  ],
  toolbarConfig: {
    slots: {
      buttons: 'toolbar_buttons'
    },
    zoom: {
      iconIn: "vxe-icon-fullscreen",
      iconOut: "vxe-icon-minimize",
    },
    custom: {
      icon: "iconfont icon-shezhi",
    },
    tools: [
      { code: 'export', icon: 'iconfont icon-xiazai', circle: true, filename: 'test.xlsx' },
    ],
  },
  customConfig: {
    allowFixed: false,
    storage: true,
    checkMethod({ column }) {
      if (['program', 'account_name'].includes(column.field)) {
        return false
      }
      return true
    }
  },
  exportConfig: { filename: '网站消耗', type: 'xlsx' },
  data: [],
  showFooter: true,
  footerData: [{ program: "总数" }]
});

const resetFormBtn = () => {
  if (!formRef.value) return
  formRef.value.resetFields()
  fetchData()
}

const fetchData = () => {
  api.consumption.getSiteSpent({
    timeRange: form.value.timeRange,
    account: form.value.account,
    site_name: form.value.site_name
  }).then(res => {
    gridOptions.data = res.data
    gridOptions.footerData = [{ program: "总数", ...res.total }]
  })
}

onMounted(() => {
  fetchData()
})
</script>

<style lang="less" scoped>
.table-title {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  font-family: 'PingFangSC-Medium, PingFang SC';
}

:deep(.vxe-toolbar) {
  padding: 12px 20px;

  .vxe-tools--wrapper {
    margin-right: 12px;
  }

  .vxe-button.type--button.is--circle {
    border-radius: 4px;
  }
}

:deep(.vxe-table) {
  .vxe-cell {
    padding: 0 20px;
  }

  .vxe-header--row {
    color: #262626;
    background-color: #F5F7FA;
    font-family: 'PingFangSC-Medium, PingFang SC';
  }

  .row--level-0 .vxe-cell--label {
    color: #262626;
  }

  .row--level-2 .vxe-cell--tree-node {
    color: #595959;
    padding-left: 20px !important;
  }

  .row--level-1 {
    background-color: #f8f8f9;
  }

  .row--level-1 .vxe-cell--label {
    color: #262626;
  }

  .vxe-table-custom--body li:nth-child(-n + 2) {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .vxe-footer--row {
    background-color: #f8f8f9;
  }
}
</style>